<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>כותרת</th>
            <th>תיאור</th>
            <th>מאושר</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="todoId in Object.keys(todos)" v-bind:key="todoId">
            <td>{{ todos[todoId].title }}</td>
            <td>{{ todos[todoId].description }}</td>
            <td>
              <v-switch
                :input-value="todos[todoId].approved"
                @change="toggle(todoId, 'approved')"
              />
            </td>
            <td>
              <v-btn icon @click="remove(todoId)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title>
            <span class="text-h5">דרישה חדשה</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="*כותרת"
                    required
                    :rules="mandatoryText"
                    v-model="title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="*תיאור"
                    hint="פרט את הדרישה לעומק"
                    persistent-hint
                    required
                    :rules="mandatoryText"
                    v-model="description"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*שדות חובה</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog(false)">
              בטל
            </v-btn>
            <v-btn color="blue darken-1" text @click="closeDialog(true)">
              שמור
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-fab-transition>
      <v-btn
        fab
        small
        bottom
        left
        class="v-btn--example"
        color="primary"
        style="position: fixed; bottom: 2vh; left: 2vw"
        @click="dialog = true"
      >
        <v-icon>mdi-note-plus-outline</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { ref, onValue, update, remove, set } from "@firebase/database";
import { database } from "../db";
export default {
  data() {
    return {
      todosRef: {},
      todos: {},
      dialog: false,
      formValid: false,
      title: "",
      description: "",
      mandatoryText: [(v) => !!v || "חובה להזין"],
    };
  },
  created() {
    this.todosRef = ref(database, "todos");
    onValue(this.todosRef, (snapshot) => {
      this.todos = snapshot.val();
    });
  },
  methods: {
    remove(id) {
      let todoRef = ref(database, `todos/${id}`);
      remove(todoRef);
    },
    toggle(id, field) {
      this.todos[id][field] = !this.todos[id][field];
      let todoRef = ref(database, `todos/${id}`);
      update(todoRef, this.todos[id]);
    },
    closeDialog(save) {
      if (save) {
        this.$refs.form.validate();
        if (this.formValid) {
          set(ref(database, `todos/${self.crypto.randomUUID()}`), {
            title: this.title,
            description: this.description,
            approved: false,
          });
          this.$refs.form.reset();
        }
      } else {
        this.$refs.form.reset();
      }
      this.dialog = false;
    },
  },
};
</script>
