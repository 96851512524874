<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div style="display: flex; flex-direction: column">
        <h1 class="header">אמא שמחה</h1>
        <h2 class="header">כולם שמחים</h2>
      </div>
    </v-app-bar>

    <v-main>
      <mommy-todos />
    </v-main>
  </v-app>
</template>

<script>
import MommyTodos from "./components/MommyTodos.vue";
export default {
  name: "App",

  components: { MommyTodos },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.header {
  margin: 0;
  padding: 0;
}
h2.header {
  font-size: 1.1rem;
  transform: translateY(-2vh);
}
</style>
